/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'javascript-time-ago';
import keyBy from 'lodash/keyBy';
import { ToastContainer, toast } from 'react-toastify';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import '../../styles/Components/media_modal.scss';
import cuid from 'cuid';
import Video from '../Common/Video';
import UserModel from '../../models/user';
import DefaultButton from '../Input/DefaultButton';
import EditModal from './EditModal';
import axios from 'axios';

const user = new UserModel();
const editIcon = (
  <svg
    version="1.1"
    viewBox="0 0 469.336 469.336"
  >
    <g>
      <path d="M347.878,151.357c-4-4.003-11.083-4.003-15.083,0L129.909,354.414c-2.427,2.429-3.531,5.87-2.99,9.258
		c0.552,3.388,2.698,6.307,5.76,7.84l16.656,8.34v28.049l-51.031,14.602l-51.51-51.554l14.59-51.075h28.025l8.333,16.67
		c1.531,3.065,4.448,5.213,7.833,5.765c0.573,0.094,1.146,0.135,1.708,0.135c2.802,0,5.531-1.105,7.542-3.128L317.711,136.26
		c2-2.002,3.125-4.712,3.125-7.548c0-2.836-1.125-5.546-3.125-7.548l-39.229-39.263c-2-2.002-4.708-3.128-7.542-3.128h-0.021
		c-2.844,0.01-5.563,1.147-7.552,3.159L45.763,301.682c-0.105,0.107-0.1,0.27-0.201,0.379c-1.095,1.183-2.009,2.549-2.487,4.208
		l-18.521,64.857L0.409,455.73c-1.063,3.722-0.021,7.736,2.719,10.478c2.031,2.033,4.75,3.128,7.542,3.128
		c0.979,0,1.958-0.136,2.927-0.407l84.531-24.166l64.802-18.537c0.195-0.056,0.329-0.203,0.52-0.27
		c0.673-0.232,1.262-0.61,1.881-0.976c0.608-0.361,1.216-0.682,1.73-1.146c0.138-0.122,0.319-0.167,0.452-0.298l219.563-217.789
		c2.01-1.991,3.146-4.712,3.156-7.558c0.01-2.836-1.115-5.557-3.125-7.569L347.878,151.357z"
      />
      <path d="M456.836,76.168l-64-64.054c-16.125-16.139-44.177-16.17-60.365,0.031l-39.073,39.461
		c-4.135,4.181-4.125,10.905,0.031,15.065l108.896,108.988c2.083,2.085,4.813,3.128,7.542,3.128c2.719,0,5.427-1.032,7.51-3.096
		l39.458-39.137c8.063-8.069,12.5-18.787,12.5-30.192S464.899,84.237,456.836,76.168z"
      />
    </g>
  </svg>
);
const heartIcon = (
  <svg viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg"><path class="svg-white" d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0" /></svg>
);
const commentIcon = (
  <svg id="Capa_1" enableBackground="new 0 0 511.072 511.072" height="512" viewBox="0 0 511.072 511.072" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Speech_Bubble_48_"><g><path class="svg-white" d="m74.39 480.536h-36.213l25.607-25.607c13.807-13.807 22.429-31.765 24.747-51.246-36.029-23.644-62.375-54.751-76.478-90.425-14.093-35.647-15.864-74.888-5.121-113.482 12.89-46.309 43.123-88.518 85.128-118.853 45.646-32.963 102.47-50.387 164.33-50.387 77.927 0 143.611 22.389 189.948 64.745 41.744 38.159 64.734 89.63 64.734 144.933 0 26.868-5.471 53.011-16.26 77.703-11.165 25.551-27.514 48.302-48.593 67.619-46.399 42.523-112.042 65-189.83 65-28.877 0-59.01-3.855-85.913-10.929-25.465 26.123-59.972 40.929-96.086 40.929zm182-420c-124.039 0-200.15 73.973-220.557 147.285-19.284 69.28 9.143 134.743 76.043 175.115l7.475 4.511-.23 8.727c-.456 17.274-4.574 33.912-11.945 48.952 17.949-6.073 34.236-17.083 46.99-32.151l6.342-7.493 9.405 2.813c26.393 7.894 57.104 12.241 86.477 12.241 154.372 0 224.682-93.473 224.682-180.322 0-46.776-19.524-90.384-54.976-122.79-40.713-37.216-99.397-56.888-169.706-56.888z" /></g></g></svg>
);
const MediaModal = ({
  media,
  onClose,
}) => {
  TimeAgo.addLocale(en);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [editingComment, setEditingComment] = useState({});
  const [dfResponses, setDFResponses] = useState([]);
  const [isReply, setIsReply] = useState(false);
  const [replyIndex, setReplyIndex] = useState(0);
  const [editComment, setEditComment] = useState('');
  const [comments, setComments] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [avgSentiment, setAvgSentiment] = useState(0);
  const [commentCount, setCommentCount] = useState(media.comments_count);
  const [nextPage, setNextPage] = useState(media.comments && media.comments.data && media.comments.paging !== undefined ? media.comments.paging.next : null);
  const wrapperRef = useRef(null);
  clickOutsideToExit(wrapperRef);
  // Create relative date/time formatter.
  const timeAgo = new TimeAgo('en-US');

  const getReplies = async replies => Promise.all(
    replies.map(async (reply) => {
      const newData = await user.getReplyMeta(reply.id);
      return newData.comment;
    }),
  ).then(resolvedValues => resolvedValues);

  const getComments = async cmnts => Promise.all(
    cmnts.map(async (comment) => {
      const newData = await user.getCommentMeta(comment.id);
      if (newData.comment.replies !== undefined) {
        const reps = newData.comment.replies.data || newData.comment.replies;
        if (reps.length > 0) {
          const replies = await getReplies(reps);
          newData.comment.replies = replies;
        }
      }
      return newData.comment;
    }),
  ).then(resolvedValues => resolvedValues);
  let mediaItem = '';
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const usr = await user.getUserMeta();
      const mediaSentiment = await user.getSentimentAnalysis(media.id);
      console.log(mediaSentiment);
      const totalValue = mediaSentiment.data.reduce((prev, current) => {
        return prev + current.value
      }, 0);
      setAvgSentiment(totalValue / mediaSentiment.data.length);
      // TODO: add this back once comment ai is better
      // const responses = await user.getAllComments();
      // setDFResponses(responses);
      setCurrentUser(usr);
      let mediaInsights;
      if (media.media_type === 'CAROUSEL_ALBUM') {
        mediaInsights = await user.getMediaInsights(media.id, true);
      } else if (media.media_type === 'VIDEO') {
        mediaInsights = await user.getMediaInsights(media.id, false, true);
      } else {
        mediaInsights = await user.getMediaInsights(media.id);
      }
      if (mediaInsights.invalid) {
        toast.error(mediaInsights.message, {
          position: toast.POSITION.TOP_LEFT
        });
      } else {
        setMetrics(keyBy(mediaInsights.data, 'name'));
        if (media.comments !== undefined) {
          const newComments = await getComments(media.comments.data);
          setComments(newComments);
        }
      }
      setIsLoading(false);
    })();
  }, []);
  function clickOutsideToExit(ref) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !displayEditModal) {
        onClose();
      }
    }
  
    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  // const comments = media.comments.data;
  switch (media.media_type) {
    case 'IMAGE':
      mediaItem = (
        <div
          style={{ backgroundImage: `url(${media.media_url})` }}
          key={`${media.id}-image`}
          className="modal-media-object"
        />
      );
      break;
    case 'CAROUSEL_ALBUM':
      mediaItem = (
        <div
          style={{ backgroundImage: `url(${media.media_url})` }}
          key={`${media.id}-image`}
          className="modal-media-object"
        />
      )
      break;
    case 'VIDEO':
      mediaItem = (
        <div className="modal-media-object" key={`${media.id}-video`}>
          <Video videoSrcURL={media.media_url} videoTitle={media.caption} autoplay />
        </div>
      );
      break;
    default:
      mediaItem = <></>;
      break;
  }
  const displaySelectOptions = (comment, index, isAReply, repIndex) => {
    // if (comment.username === currentUser.username.toLowerCase()) {
    //   setEditIndex(index);
    //   setEditComment(comment.text);
    //   setEditingComment(comment);
    //   setReplyIndex(repIndex);
    //   setIsReply(isAReply);
    //   setDisplayEditModal(true);
    // }
  };

  const save = async (comment) => {
    const comnts = comments;
    let commentId = null;
    if (isReply) {
      commentId = comnts[editIndex].id;
      comnts[editIndex].replies[replyIndex].text = comment;
    } else {
      comnts[editIndex].text = comment;
    }
    // Delete old comment
    // Check if its a reply or a comment on the media object.
    if (isReply && commentId !== null) {
      const d = await user.deleteComment(editingComment.id);
      const update = await user.updateComment(commentId, media.id, isReply, comment);
    } else {
      // DO NOT ALLOW UPDATING NORMAL COMMENTS.
      // const d = await user.deleteComment(editingComment.id);
      // const update = await user.updateComment(null, media.id, false, comment);
    }

    setComments(comnts);
    setDisplayEditModal(false);
    setEditComment(comment);
    setEditIndex(0);
    setReplyIndex(0);
    setIsReply(false);
  };
  const checkResponses = (comment) => {
    // TODO: add this back when comment ai is better
    // const filteredDFRespones = dfResponses.filter((response) => response.data.commentId === comment.id);
    // return filteredDFRespones;
    return [];
  }
  const edit = (resp) => {
    // TODO: add edit eventually
    toast.warn("Editing a Reach Response is not quite available. Please stay tuned.", {
      position: toast.POSITION.TOP_LEFT
    });
  }
  const approve = async (resp) => {

    try {
      await user.approveResponse(resp);
      toast.success("Successfully approved response.", {
        position: toast.POSITION.TOP_LEFT
      });
    } catch (err) {
      toast.error("There was an error approving response. Please try again.", {
        position: toast.POSITION.TOP_LEFT
      });
    }
  }
  const reject = async (resp) => {
    try {
      await user.rejectResponse(resp);
      toast.success("Successfully rejected response.", {
        position: toast.POSITION.TOP_LEFT
      });
    } catch (err) {
      toast.error("There was an error approving response. Please try again.", {
        position: toast.POSITION.TOP_LEFT
      });
    }
  }
  const getNextComments = async () => {
    setIsLoading(true);
    const moreComments = await axios.get(nextPage);
    const newComments = moreComments.data.data;
    const newPaging = moreComments.data && moreComments.data.paging !== undefined ? moreComments.data.paging.next : null;
    setNextPage(newPaging);
    const oldComments = comments;
    const newSetOfComments = await getComments(newComments);
    const latestSetOfComments = oldComments.concat(newSetOfComments);
    setComments(latestSetOfComments);
    setCommentCount(latestSetOfComments.length);
    setIsLoading(false);
  }
  const commaFormatNumber = (value) => {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="modal-background">
      <div
        role="button"
        tabIndex={-1}
        className="close-button white"
        onKeyDown={() => {}}
        onClick={() => onClose()}
      />
      <div className="modal-container" ref={wrapperRef}>
        <div className="media-item">
          {mediaItem}
          {metrics.reach !== undefined && (
          <div className="analytics-container">
            <div className="likes">
              {heartIcon} {commaFormatNumber(media.like_count) || 0}
            </div>
            <div className="comments">
              {commentIcon} {commaFormatNumber(media.comments_count) || 0}
            </div>
            <div className="comments">
              Reach {commaFormatNumber(metrics.reach.values[0].value) || 0}
            </div>
            <div className="comments">
              Engagement {commaFormatNumber(metrics.engagement.values[0].value) || 0}
            </div>
            <div className="comments">
              Impressions {commaFormatNumber(metrics.impressions.values[0].value) || 0}
            </div>
            <div className="comments">
              Sentiment {avgSentiment.toFixed(2) || 0}
            </div>
          </div>
          )}
        </div>
        <div className="comments-container">
          {media.comments_count === 0 && <p className="no-comments">There are no comments</p>}
          <div className="comments-list">
            {comments.map((comment, index) => {
              // const response = checkResponses(comment);
              return (
                <div key={cuid.slug()} className="comment-container">
                  <div
                    role="button"
                    tabIndex={-1}
                    className="comment"
                    onKeyDown={() => {}}
                    onClick={() => displaySelectOptions(comment, index, false, undefined)}
                  >
                    {/* {currentUser.username.toLowerCase() === comment.username && editIcon} */}
                    <h6>{comment.username}</h6>
                    <p>{comment.text}</p>
                    <span>{timeAgo.format(new Date(comment.timestamp))}</span>
                  </div>
                  {comment.replies !== undefined && comment.replies.map((reply, repIndex) => (
                    <div
                      className="comment indent"
                      role="button"
                      tabIndex={-1}
                      key={cuid.slug()}
                    >
                      {/* {currentUser.username.toLowerCase() === reply.username && editIcon} */}
                      <h6>{reply.username}</h6>
                      <p>{reply.text}</p>
                      <span>{timeAgo.format(new Date(reply.timestamp))}</span>
                    </div>
                  ))}
                  {/* {response.length > 0 && (
                    <div
                      className="comment indent"
                      role="button"
                      tabIndex={-1}
                      key={cuid.slug()}
                    >
                      <div className="buttons">
                        <button onClick={() => approve(response[0])} className="accept">Approve</button>
                        <button onClick={() => reject(response[0])} className="reject">Reject</button>
                        <button onClick={() => edit(response[0])} className="edit">Edit {editIcon}</button>
                        
                      </div>
                      <h6>Reach Response</h6>
                      <p>{response[0].reply}</p>
                      <span>{timeAgo.format(new Date(response[0].createdAt._seconds * 1000))}</span>
                    </div>
                  )} */}
                </div>
              )
            })
          }
          {nextPage !== undefined && nextPage !== null && <DefaultButton text="View More" loading={isLoading} callback={() => getNextComments()} />}
          </div>
        </div>
        
      </div>
      <EditModal
        save={comment => save(comment)}
        onClose={() => setDisplayEditModal(false)}
        displayEditModal={displayEditModal}
        comment={editComment}
      />
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </div>
  );
};

MediaModal.propTypes = {
  media: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MediaModal;
