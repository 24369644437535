import React, { useEffect, useState, useContext} from 'react';
import firebase from 'gatsby-plugin-firebase';
import cuid from 'cuid';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'firebase/database';
import InputForm from '../../Common/InputForm';
import DefaultButton from '../../Input/DefaultButton';
import { profileIcon } from '../../Icons/icons';
// import User from '../../../models/user';
import { getCurrentServiceId, setCurrentCompanyId, setCurrentServiceId, getCurrentServiceIGId } from '../../../models/firebase';
import LoadingSpinner from '../../Common/LoadingSpinner';

// const model = new User();

const Account = ({ model }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [currentService, setCurrentService] = useState({});
  const [currentCompany, setCurrentCompany] = useState({});
  const [currentMembers, setCurrentMembers] = useState([]);
  const [pendingInvitees, setPendingInvitees] = useState([]);
  const [invites, setInvites] = useState([]);
  const [values, setValues] = useState({
    firstName: {
      value: '',
      valid: /.+/g
    },
    lastName: {
      value: '',
      valid: /.+/g
    },
    email: {
      value: '',
      valid: /.+/g
    },
    position: {
      value: '',
      valid: /.+/g
    },
    companyName: {
      value: '',
      valid: /.+/g
    },
    image: {
      value: '',
    },
    inviteEmail: {
      value: '',
      valid: /.+/g,
    }
  });
  const getCompaniesRequest = async companies => Promise.all(
    companies.map(async (company) => {
      return await company.data();
    }),
  ).then(resolvedValues => resolvedValues);
  const getCompanyInfo = async (companyInfo) => Promise.all(
    companyInfo.map(async (info) => {
      return await model.getCompanyById(info.companyId)
    })
  )
  const getServices = async () => {
    const getAllServices = await model.getAllServices();
    setServiceOptions(getAllServices.map((service) => { return { value: service.id, label: service.data.service_name }}));
    const currentServ = await model.getServiceData();
    const currentServiceId = await getCurrentServiceId(model);
    setCurrentService({ value: currentServiceId, label: currentServ.service_name})
  }
  const getInvites = async () => {
    const getAllInvitesByEmail = await model.getAllInvites();

    const items = await Promise.all(getAllInvitesByEmail.map(async (invite) => {
      const invitee = await model.getCompanyById(invite.companyId)
      return {
        company: invitee,
        id: invite.id,
      }
    }))
    setInvites(items);
  }
  const getCompanies = async () => {
    const getAllCompanies = await model.getAllCompanies();
    const userCompanies = await getCompaniesRequest(getAllCompanies);
    const companies = await getCompanyInfo(userCompanies);
    const currentComp = await model.getCurrentCompanyId();
    setCurrentCompany({ value: currentComp.id, label: currentComp.name })
    setCompanyOptions(companies.map((value) => { return { value: value.id, label: value.name } }));
  }
  const getCompanyInvitesAndMembers = async () => {
    const users = await model.getInvites();
    setPendingInvitees(users);
    const companyUsers = await model.getAllUsersTiedToCompany();
    setCurrentMembers(companyUsers);
  }
  useEffect(() => {
    firebase.auth().onAuthStateChanged((usr) => {
      if (usr) {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get()
          .then(async (doc) => {
            // If true then no expired else do the auto load;
            if (doc && doc.data()) {
              setValues(prevState => ({
                ...prevState,
                image: {
                  value: doc.data().profile_url || '',
                },
                email: {
                  value: doc.data().email || '',
                  valid: prevState.email.valid,
                },
                firstName: {
                  value: doc.data().first_name || '',
                  valid: prevState.firstName.valid,
                },
                lastName: {
                  value: doc.data().last_name || '',
                  valid: prevState.lastName.valid,
                },
                companyName: {
                  value: doc.data().company_name || '',
                  valid: prevState.companyName.valid,
                },
                position: {
                  value: doc.data().position || '',
                  valid: prevState.position.valid,
                }
              }))
            }
          });
      }
    });
    
    let mounted = true;
    (async () => {
      setIsLoading(true);
      await getInvites();
      await getCompanies();
      await getServices();
      await getCompanyInvitesAndMembers();
      setIsLoading(false);
    })()
    return () => mounted = false
  }, [])
  const updateValues = (id, value) => {
    setValues(prevState => ({
      ...prevState,
      [id]: {
        value: value,
        valid: prevState[id].valid,
      }
    }))
  }
  const save = async () => {
    const db = firebase.firestore();
    const sp = await db.collection('users').doc(firebase.auth().currentUser.uid).update({
      first_name: values.firstName.value,
      last_name: values.lastName.value,
      position: values.position.value,
    });
    toast.success("Successfully updated user information.", {
      position: toast.POSITION.TOP_LEFT
    });
  }
  const invite = async () => {
    // handle email validation.
    setIsInviteLoading(true);
    if (values.inviteEmail.value !== '') {
      const invite = await model.inviteUser(values.inviteEmail.value);
      if (invite.invalid) {
        toast.error(invite.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      } else {
        toast.success(`Invite to ${values.inviteEmail.value} sent!`, {
          position: toast.POSITION.TOP_LEFT,
        });
      }
      // Get all invites again.
      const users = await model.getInvites();
      setPendingInvitees(users);
      setValues(prevState => ({
        ...prevState,
        inviteEmail: {
          value: '',
          valid: prevState.inviteEmail.valid,
        }
      }))
    } else {
      toast.error("Please enter a valid email.", {
        position: toast.POSITION.TOP_LEFT
      });
    }
    setIsInviteLoading(false);
  }
  const handleUpload = async (e) => {
    e.preventDefault()

    const file = e.target.files[0];
    var metadata = { contentType: file.type};
    // let image = { preview: URL.createObjectURL(e.target.files[0]), raw: e.target.files[0] }
    const db = firebase.firestore();
    const childRef = 'profile'
    const ref = firebase.storage().ref(childRef).child(file.name)
    const uploadTask = ref.put(file)
    uploadTask.then((snapshot) => {
      ref.getDownloadURL().then( async (result) => {
        const sp = await db.collection('users').doc(firebase.auth().currentUser.uid).update({
          profile_url: result,
        });
        setValues(prevState => ({
          ...prevState,
          image: {
            value: result,
          }
        }))
      })
    })
  }
  const onServiceChange = async (e) => {
    setCurrentService(e);
    await setCurrentServiceId(e.value, model);
    await getCurrentServiceIGId(model, true);
    toast.success("Successfully updated service", {
      position: toast.POSITION.TOP_LEFT
    });
  }
  const onCompanyChange = async (e) => {
    setIsLoading(true);
    setCurrentCompany(e);
    await setCurrentCompanyId(e.value, model);
    const services = await model.getAllServices();
    const currentService = services[0];
    await setCurrentServiceId(currentService.id, model);
    await getCurrentServiceIGId(model, true);
    await getServices();
    setIsLoading(false);
    toast.success("Successfully updated company", {
      position: toast.POSITION.TOP_LEFT
    });
  }
  const cancel = async (id) => {
    setIsLoading(true)
    const rejectResponse = await model.rejectUserInvite(id);
    if (rejectResponse.invalid) {
      toast.error(rejectResponse.message, {
        position: toast.POSITION.TOP_LEFT
      });
    } else {
      toast.success('Successfully removed invite.', {
        position: toast.POSITION.TOP_LEFT
      })
    }
    await getCompanyInvitesAndMembers()
    setIsLoading(false)
    
  }
  const resend = async (id) => {
    setIsLoading(true)
    const resendResponse = await model.resendInvite(id);
    if (resendResponse.invalid) {
      toast.error(resendResponse.message, {
        position: toast.POSITION.TOP_LEFT
      });
    } else {
      toast.success('Successfully resent invite.', {
        position: toast.POSITION.TOP_LEFT
      })
    }
    setIsLoading(false)
  }

  const accept = async (id) => {
    setIsLoading(true);
    const acceptInvite = await model.completeInvite(id);
    if (acceptInvite.invalid) {
      toast.error(acceptInvite.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      await getInvites();
      await getCompanies();
      await getServices();
    }
    setIsLoading(false);
  }
  const reject = async (id) => {
    setIsLoading(true);
    const rejectInvite = await model.rejectUserInvite(id);
    if (rejectInvite.invalid) {
      toast.error(rejectInvite.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      await getInvites();
    }
    setIsLoading(false);
  }
  // const handleFileUpload = (e) => {
  //   if (typeof event.preventDefault === 'function') {
  //     event.preventDefault();
  //   }
  //   if (typeof event.stopPropagation === 'function') {
  //     event.stopPropagation();
  //   }
  //   event.returnValue = false;
  //   document.getElementById('upload').click();
  //   return false;
  // }
  return (
    <div className="dashboard dashboard__account">
      <div className="dashboard-container account">
        <h2 className="desktop">ACCOUNT</h2>
        <LoadingSpinner isLoading={isLoading} />
        <div className="dashboard-content rowed">
      
          <div className="card two-thirds height-full hidden-overflow account-card">
            <h5>Edit Profile</h5>
            <div className="card-body full">
              <div className="row">
                <div className="half-col">
                  <InputForm
                    placeholder="First Name"
                    title="First Name"
                    value={values.firstName.value}
                    onChange={updateValues}
                    id="firstName"
                  />
                </div>
                <div className="half-col">
                  <InputForm
                    placeholder="Last Name"
                    title="Last Name"
                    value={values.lastName.value}
                    onChange={updateValues}
                    id="lastName"
                  />
                </div>
              </div>
              <div className="row">
                <div className="half-col">
                  <InputForm
                    placeholder="Email"
                    title="Email"
                    value={values.email.value}
                    onChange={updateValues}
                    id="email"
                    disabled
                  />
                </div>
                <div className="half-col">
                  <InputForm
                    placeholder="Position Title"
                    title="Position Title"
                    value={values.position.value}
                    onChange={updateValues}
                    id="position"
                  />
                </div>
              </div>
              <div className="row">
                <div className="half-col">
                  <DefaultButton
                    text="Save"
                    disabled={false}
                    callback={save}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card one-third height-author hidden-overflow">
            <div className="card-body full card__author">
              <div className="author">
                <div className="backdrop block-one" />
                <div className="backdrop block-two" />
                <div className="backdrop block-three" />
                <div className="backdrop block-four" />
                
                <label className="label" htmlFor="upload">
                  <div className="avatar">
                    { values.image.value !== '' ? <img src={values.image.value} alt="profile" /> : profileIcon }
                  </div>
                
                  <input type="file" id="upload" style={{ display: 'none' }} className="uploader" onChange={handleUpload} />
                </label>
                <div className="author-container">
                  <h5>{values.firstName.value} {values.lastName.value}</h5>
                  <h3>{values.position.value}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-content rowed spaced">
          <div className="card full height-full account-card">
            <h5>Account</h5>
            <p>Select an account to focus on.</p>
            <div className="card-body full">
              <div className="row">
                <div className="half-col">
                  <div className="form-group">
                    <label>Account</label>
                    <Select onChange={(e) => onServiceChange(e)} value={currentService} className="reach-select-container" classNamePrefix="reach-select" options={serviceOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-content rowed spaced">
          <div className="card full height-full account-card">
            <h5>Company</h5>
            <p>Change your company here.</p>
            <div className="card-body full">
              <div className="row">
                <div className="half-col">
                  <div className="form-group">
                    <label>Company</label>
                    <Select onChange={(e) => onCompanyChange(e)} value={currentCompany} className="reach-select-container" classNamePrefix="reach-select" options={companyOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-content rowed spaced">
          <div className="card full height-full account-card">
            <h5>Invite Members</h5>
            <p>Enter the email of the person you want to invite.</p>
            <div className="card-body full">
              <div className="row align-around">
                <div className="half-col">
                  <div className="form-group">
                    {/* <label>Email</label> */}
                    <InputForm
                      placeholder="Email"
                      title="Email"
                      value={values.inviteEmail.value}
                      onChange={updateValues}
                      id="inviteEmail"
                    />
                  </div>
                </div>
                <div className="half-col">
                  <div className="form-group">
                    <DefaultButton
                      text="Invite"
                      loading={isInviteLoading}
                      disabled={false}
                      callback={invite}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="half-col">
                  <div className="form-group">
                    <label>Pending Invitations</label>
                    <div className="member-list">
                      {pendingInvitees && pendingInvitees.length > 0 && pendingInvitees.map((invitee) => (
                        <div className="member-item" key={cuid.slug()}>
                          <h4>{invitee.email.toUpperCase()}</h4>
                          <p className="nobottom">{`${new Date(invitee.createdAt._seconds * 1000).toLocaleString()}`}</p>
                          <button onClick={() => resend(invitee.id)} className="accept">Resend</button>
                          <button onClick={() => cancel(invitee.id)} className="reject">Cancel</button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Current Members</label>
                    <div className="member-list">
                      {currentMembers && currentMembers.length > 0 && currentMembers.map((member) => (
                        <div className="member-item" key={cuid.slug()}>
                          {member.profile_url && member.profile_url !== '' && <img src={member.profile_url} alt={member.first_name} />}
                          {(member.profile_url === null || member.profile_url === '') && profileIcon}
                          <h4>{member.first_name.toUpperCase()} {member.last_name.toUpperCase()}</h4>
                          <p className="nobottom">{member.email}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-content rowed spaced" id="invites">
          <div className="card full height-full account-card">
            <h5>Your Invites</h5>
            {invites.length === 0 && <p>There are currently no pending invites for you.</p>}
            {invites.length > 0 && (
              <div className="card-body full">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <div className="member-list">
                        {invites && invites.length > 0 && invites.map((invite) => (
                          <div className="member-item" key={cuid.slug()}>
                            <h4 style={{opacity: 0.7}}>You've been invited by <b style={{ opacity: 1 }}>{invite.company.name}</b></h4>
                            <button onClick={() => accept(invite.id)} className="accept">Accept</button>
                            <button onClick={() => reject(invite.id)} className="reject">Reject</button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </div>
  );
}

export default Account;
