import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const MobilePortalNav = ({
  onOpen,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const onClick = () => {
    const opened = isOpened;
    setIsOpened(!opened);
    onOpen(!opened);
  };
  return (
    <nav className={`navbar navbar-expand-lg navbar-absolute navbar-transparent`}>
      <div className="container-fluid">
        <div className="navbar-wrapper">
          <div
            className={`navbar-toggle d-inline ${isOpened && 'toggled'}`}
            role="button"
            tabIndex="-1"
            onKeyDown={() => {}}
            onClick={() => onClick()}
          >
            <button type="button" className="navbar-toggler">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

MobilePortalNav.propTypes = {
  onOpen: PropTypes.func.isRequired,
};

export default MobilePortalNav;
