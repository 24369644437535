import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import cuid from 'cuid';
import LoadingSpinner from '../../Common/LoadingSpinner';
import DefaultButton from '../../Input/DefaultButton';
import Video from '../../Common/Video';
import MediaModal from '../../Modal/MediaModal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getCurrentServiceId, setCurrentServiceId, getCurrentServiceIGId } from '../../../models/firebase';

const heartIcon = (
  <svg viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg"><path class="svg-white" d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0" /></svg>
);
const commentIcon = (
  <svg viewBox="0 0 511.072 511.072" xmlns="http://www.w3.org/2000/svg"><g id="Speech_Bubble_48_"><g><path class="svg-white" d="m74.39 480.536h-36.213l25.607-25.607c13.807-13.807 22.429-31.765 24.747-51.246-36.029-23.644-62.375-54.751-76.478-90.425-14.093-35.647-15.864-74.888-5.121-113.482 12.89-46.309 43.123-88.518 85.128-118.853 45.646-32.963 102.47-50.387 164.33-50.387 77.927 0 143.611 22.389 189.948 64.745 41.744 38.159 64.734 89.63 64.734 144.933 0 26.868-5.471 53.011-16.26 77.703-11.165 25.551-27.514 48.302-48.593 67.619-46.399 42.523-112.042 65-189.83 65-28.877 0-59.01-3.855-85.913-10.929-25.465 26.123-59.972 40.929-96.086 40.929zm182-420c-124.039 0-200.15 73.973-220.557 147.285-19.284 69.28 9.143 134.743 76.043 175.115l7.475 4.511-.23 8.727c-.456 17.274-4.574 33.912-11.945 48.952 17.949-6.073 34.236-17.083 46.99-32.151l6.342-7.493 9.405 2.813c26.393 7.894 57.104 12.241 86.477 12.241 154.372 0 224.682-93.473 224.682-180.322 0-46.776-19.524-90.384-54.976-122.79-40.713-37.216-99.397-56.888-169.706-56.888z" /></g></g></svg>
);

const CommentAI = ({ model }) => {
  const [media, setMedia] = useState([]);
  const [next, setNext] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openMedia, setOpenMedia] = useState(undefined);
  const [insightPreview, setInsightPreview] = useState(undefined);
  const [serviceName, setServiceName] = useState('');
  const [serviceOptions, setServiceOptions] = useState([]);
  const [currentService, setCurrentService] = useState({});
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const getServiceData = await model.getServiceData();
        const businessId = getServiceData.instagram_id;

        // Check to see if we even need to load this
        if(window.innerWidth >= 1024) {
          getServices();
          setServiceName(getServiceData.service_name);
        }

        const mediaObjects = await model.getAllUserMediaObjects(businessId);
        setMedia(mediaObjects.data);
        setNext(mediaObjects.next);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    })();
  }, []);
  const displayInsightPreview = (mediaItem) => {
    const element = document.getElementById(`${mediaItem.id}-preview`);
    element.style.display = 'flex';
  };

  const hideInsightPreview = (mediaItem) => {
    const element = document.getElementById(`${mediaItem.id}-preview`);
    element.style.display = 'none';
  };

  const displayMediaItem = (mediaItem) => {
    setOpenMedia(mediaItem);
    setOpenModal(true);
    // Handle Modal for media object;
  };

  const closeModal = () => {
    setOpenMedia(undefined);
    setOpenModal(false);
  };

  const showMore = async () => {
    // Get more items
    const mediaObjects = media;
    setIsLoading(true);
    const newMedia = await model.getNextListOfItems(next);
    setMedia(mediaObjects.concat(newMedia.data));
    setNext(newMedia.next);
    setIsLoading(false);
  };

  const shrinkFormatNumber = (value) => {
    var ranges = [
        { divider: 1e9, suffix: 'B' },
        { divider: 1e6, suffix: 'M' },
        { divider: 1e3, suffix: 'K' }
    ];
    for (var i = 0; i < ranges.length; i++) {
      if (value >= ranges[i].divider) {
          return (value / ranges[i].divider).toFixed(1).toString() + ranges[i].suffix;
      }
    }
    return value;
  };
  const getServices = async () => {
    const getAllServices = await model.getAllServices();
    setServiceOptions(getAllServices.map((service) => { return { value: service.id, label: service.data.service_name }}));
    const currentServ = await model.getServiceData();
    const currentServiceId = await getCurrentServiceId(model);
    setCurrentService({ value: currentServiceId, label: currentServ.service_name})
    setServiceName(currentServ.service_name);
  }
  const onServiceChange = async (e) => {
    setCurrentService(e);
    await setCurrentServiceId(e.value, model);
    await getCurrentServiceIGId(model, true);
    toast.success("Successfully updated service", {
      position: toast.POSITION.TOP_RIGHT
    });
  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#00800000",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#00800000",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#00800000"
      },
      textColor: "white",
    }),
    container: provided => (
      {
      ...provided,
      // (average_letter_size * selected_value.length) + other_elements_sizes
      width: `${(10 * serviceName.length) + 55}px`
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    })
  };

  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <div className="service-dropdown-container">
          <Select styles={customStyles} onChange={(e) => onServiceChange(e)} value={currentService} className="reach-select-container" classNamePrefix="reach-select" options={serviceOptions} components={{IndicatorSeparator: () => null}}/>
        </div>
        <div className="dashboard-content">
          <LoadingSpinner isLoading={isLoading} />
        </div>
      </div>
      <div className="dashboard-charts">
        <div className="media-list">
          {media.map((mediaItem, index) => {
            switch (mediaItem.media_type) {

              case 'IMAGE':
                return (
                  <div
                    role="button"
                    tabIndex={-1}
                    key={`${mediaItem.id}-${index}`}
                    className="media-object-container"
                    onKeyDown={() => {}}
                    onClick={() => displayMediaItem(mediaItem)}
                    onMouseEnter={() => displayInsightPreview(mediaItem)}
                    onMouseLeave={() => hideInsightPreview(mediaItem)}>
                    <div
                      style={{ backgroundImage: `url(${mediaItem.media_url})` }}
                      className="media-object"
                      >
                    </div>
                    <div className="insight-preview-overlay" id={`${mediaItem.id}-preview`}>
                      <div className="likes">
                        {heartIcon} {shrinkFormatNumber(mediaItem.like_count) || 0}
                      </div>
                      <div className="comments">
                        {commentIcon} {shrinkFormatNumber(mediaItem.comments_count) || 0}
                      </div>
                    </div>
                  </div>
                );
              case 'VIDEO':
                return (
                  <div
                    role="button"
                    tabIndex={-1}
                    key={`${mediaItem.id}-${index}`}
                    className="media-object-container"
                    onKeyDown={() => {}}
                    onClick={() => displayMediaItem(mediaItem)}
                    onMouseEnter={() => displayInsightPreview(mediaItem)}
                    onMouseLeave={() => hideInsightPreview(mediaItem)}>
                      <div className="media-object">
                        <Video 
                          onClick={() => displayMediaItem(mediaItem)} 
                          videoSrcURL={mediaItem.media_url} 
                          videoTitle="Instagram Video" 
                        />
                      </div>
                      <div className="insight-preview-overlay" id={`${mediaItem.id}-preview`}>
                        <div className="likes">
                          {heartIcon} {shrinkFormatNumber(mediaItem.like_count) || 0}
                        </div>
                        <div className="comments">
                          {commentIcon} {shrinkFormatNumber(mediaItem.comments_count) || 0}
                        </div>
                      </div>
                  </div>
                );
              case 'CAROUSEL_ALBUM':
                return (
                  <div
                    role="button"
                    tabIndex={-1}
                    key={`${mediaItem.id}-${index}`}
                    className="media-object-container"
                    onKeyDown={() => {}}
                    onClick={() => displayMediaItem(mediaItem)}
                    onMouseEnter={() => displayInsightPreview(mediaItem)}
                    onMouseLeave={() => hideInsightPreview(mediaItem)}>
                    <div
                      style={{ backgroundImage: `url(${mediaItem.media_url})` }}
                      className="media-object"
                    >
                      <div />
                    </div>
                    <div className="insight-preview-overlay" id={`${mediaItem.id}-preview`}>
                      <div className="likes">
                        {heartIcon} {shrinkFormatNumber(mediaItem.like_count) || 0}
                      </div>
                      <div className="comments">
                        {commentIcon} {shrinkFormatNumber(mediaItem.comments_count) || 0}
                      </div>
                    </div>
                  </div>
                )
              default:
                return <></>;
            }
          })}
        </div>
        {next !== undefined && media.length > 0 && (
          <div className="show-more">
            <DefaultButton
              text="Show More"
              callback={() => showMore()}
            />
          </div>
        )}
      </div>
      {openModal && openMedia ? ReactDOM.createPortal((
        <MediaModal
          media={openMedia}
          onClose={() => closeModal()}
        />
      ), document.body) : null}
    </div>
  );
};

export default CommentAI;
