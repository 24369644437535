const GraphTypes = {
  totalEngagement: 'totalEngagement',
  estimatedCommunitySize: 'estimatedCommunitySize',
  profileRetention: 'profileRetention',
  commentsPerDay: 'commentsPerDay',
  likesPerDay: 'likesPerDay',
  newFollowersPerDay: 'newFollowersPerDay',
  impressionsPerDay: 'impressionsPerDay',
  reachPerDay: 'reachPerDay',
  websiteClicksPerDay: 'websiteClicksPerDay',
  textMessageClicksPerDay: 'textMessageClicksPerDay',
  emailClicksPerDay: 'emailClicksPerDay',
  profileViewsPerDay: 'profileViewsPerDay',
};


export {
  GraphTypes,
};
