import React from 'react';
import PropTypes from 'prop-types';

const Dropdown = ({
  isOpen,
  children,
  className,
}) => (
  <div className={`dropdown ${isOpen && 'opened'} ${className}`}>
    {children}
  </div>
);

Dropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  className: '',
}

export default Dropdown;
