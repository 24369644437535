import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/Components/toggle.scss';

const ToggleSwitch = ({
  toggled,
  callback,
  id,
}) => {
  const [toggleState, setToggleState] = useState(toggled ? 'on' : 'off');
  const toggle = () => {
    const newState = toggleState === 'off' ? 'on' : 'off';
    setToggleState(newState);
    callback(newState)
  }
  return <div id={id} className={`switch ${toggleState}`} onClick={toggle} />
}

ToggleSwitch.propTypes = {
  toggled: PropTypes.string,
  id: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  toggled: 'off',
};

export default ToggleSwitch;
