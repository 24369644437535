import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Logo from '../../images/imgcomps/Logo';

const SidebarDesktop = ({
  activeTab,
}) => (
  <div className="side-bar desktop">
    <div className="sidebar-wrapper">
      <div className="portal-logo">
        <Logo />
      </div>
      <ul className="sidebar-nav">
      <li className={activeTab === 'home' ? 'active' : ''}>
          <Link to="/portal">Dashboard</Link>
        </li>
        <li className={activeTab === 'media-insights' ? 'active' : ''}>
          <Link to="/portal/media-insights/">Media Insights</Link>
        </li>
        <li className={activeTab === 'account' ? 'active' : ''}>
          <Link to="/portal/account/">Account</Link>
        </li>
        {/* <li className={activeTab === 'settings' ? 'active' : ''}>
          <Link to="/portal/settings/">Settings</Link>
        </li> */}
      </ul>
    </div>
  </div>
);

SidebarDesktop.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default SidebarDesktop;
