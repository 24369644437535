import React from 'react';
import PropTypes from 'prop-types';
import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';

const ReactToPdf = ({
  filename,
  options,
  x,
  y,
  scale,
  children,
  containerClass,
}) => {
  
  const toPdf = () => {
    let elems = document.querySelectorAll(`.${containerClass}`);
    const pdf = new JsPdf(options);
    const addPages = () => {
      elems.forEach((elem, idx) => {
        html2canvas(elem, {
          logging: false,
          useCORS: true,
          scale: scale,
        }).then(canvas => {
          if(idx < elems.length - 1){
            const imgData = canvas.toDataURL('image/jpeg');
            pdf.addImage(imgData, 'JPEG', x, y);
            pdf.addPage();
          } else {

            const imgData = canvas.toDataURL('image/jpeg');
            pdf.addImage(imgData, 'JPEG', x, y);
            pdf.save(filename);
          }
        });
        // setTimeout(() => {}, 100, "Timeout adding page #" + idx);
      });
      return true;
    };
    return addPages();
  }
  return children({ toPdf: toPdf });
}

ReactToPdf.propTypes = {
  filename: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  containerClass: PropTypes.string.isRequired,
  options: PropTypes.shape({}),
  scale: PropTypes.number,
  children: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
};

ReactToPdf.defaultProps = {
  filename: 'download.pdf',
  options: undefined,
  x: 0,
  y: 0,
  scale: 1,
};

export default ReactToPdf;