/* eslint-disable react/prop-types */
/* eslint-disable global-require */
import React, { useState, useContext, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import _ from 'lodash';
import { navigate } from 'gatsby';
import SidebarDesktop from './Common/SidebarDesktop';
import MobilePortalNav from './Common/MobilePortalNav';
import '../styles/Portal/portal.scss';
import '../styles/Base/base.scss';
import '../styles/Portal/portal-nav.scss';
import MobileSideBar from './Common/MobileSideBar';
import ProfileDropdown from './Common/ProfileDropdown';
import Onboarding from './Common/Onboarding';
import GoogleTagManager from '../utils/GoogleTagManager'

// import Model from '../models/user';
let MaterialIcon;
// const model = new Model();
const PrivateRoute = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasDoneOnboarding, setHasDoneOnboarding] = useState(true);
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // User is signed in.
        navigate('/sign-in');
      } else {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get()
          .then(doc => {
            if (doc.data() && doc.data().onboarded) {
              setHasDoneOnboarding(true);
              setIsLoading(false);
            } else {
              setHasDoneOnboarding(false);
            }
          })
      }
    });
  }, []);
  const { component: Component, activeTab, model, ...rest } = props;

  const onOpen = (isOpened) => {
    setOpened(isOpened);
  };
  const onCompletion = async (values) => {
    // Save info
    firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
      first_name: values.firstName.value,
      last_name: values.lastName.value,
      onboarded: true,
    }).then(async (success) => {
      // Send welcome email.
      await model.sendWelcomeEmail();
      setHasDoneOnboarding(true);
      setIsLoading(false);
    })
  }
  return (
    <>
    <GoogleTagManager gtmId='GTM-WQHPLFM'/>
    {!hasDoneOnboarding && (
      <div className="portal-container">
        <Onboarding onCompletion={onCompletion} model={model} />
      </div> 
    )}
    {hasDoneOnboarding && !isLoading && (
      <div className="no-overflow">
        <MobileSideBar activeTab={activeTab} opened={opened} />
      
        <div className={`portal-container ${opened ? 'opened' : ''}`}>
          <div className="portal-nav">
            <MobilePortalNav onOpen={onOpen} />
            <ProfileDropdown model={model} />
          </div>
          <SidebarDesktop activeTab={activeTab} />
          <div className="portal-content-container">
            <Component {...rest} model={model} />
          </div>
        </div>
      </div>
    )}
    {isLoading && hasDoneOnboarding && (
      <div className="no-overflow">
        <div className={`portal-container ${opened ? 'opened' : ''}`}>
          <MobilePortalNav onOpen={onOpen} />
          {/* <ProfileDropdown /> */}
          <SidebarDesktop activeTab={activeTab} />
          <div className="portal-content-container" />
        </div>
      </div>
    )}
    
    </>
  );
};
export default PrivateRoute;
