import React, { useState, useContext, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import 'firebase/database';
import Toggle from '../../Common/Toggle';
import DefaultButton from '../../Input/DefaultButton';

const Settings = () => {
  const [values, setValues] = useState({
    globalTrigger: {
      value: false,
    }
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged((usr) => {
      if (usr) {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get()
          .then(async (doc) => {
            if (doc && doc.data()) {
              setValues(prevState => ({
                ...prevState,
                globalTrigger: {
                  value: doc.data().global_response_trigger || false,
                }
              }))
            }
          })
      }
    })
  }, [])

  const updateGlobalResponse = (toggled) => {
    setValues(prevState => ({
      ...prevState,
      globalTrigger: {
        value: toggled === 'on',
      }
    }))
  }
  const displaySaveModal = () => {
    
  }
  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <h2 className="desktop">SETTINGS</h2>
        <div className="dashboard-content">
          <div className="card full">
            <h5>Settings</h5>
            <div className="row">
              <div className="form-group">
                <label htmlFor="global_response_trigger">Auto Respond to Instagram Comments</label>
                <Toggle id="global_response_trigger" callback={updateGlobalResponse} toggled={values.globalTrigger.value} />
              </div>
            </div>
            <DefaultButton
              text="Save"
              callback={displaySaveModal}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings;