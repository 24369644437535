import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/Common/modal.scss';

const Modal = ({
  title,
  bodyCopy,
  children,
  onClose,
  style,
  className,
}) => {
  return (
    <div className={`fullScreen ${className || ''}`} style={style}>
      <div className="defaultModal">
        <div className="close-button" onClick={() => onClose()} />
        <div className="topContainer">
          <h3>{title}</h3>
          {bodyCopy && <p>{bodyCopy}</p>}
        </div>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  bodyCopy: PropTypes.string,
  children: PropTypes.any,
  onClose: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
}

Modal.defaultProps = {
  title: '',
  bodyCopy: '',
  children: '',
  onClose: () => {},
  style: {},
  className: null,
}

export default Modal;