import React, { useState, useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import axios from 'axios';
import { Link, navigateTo, navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { ToastContainer, toast } from 'react-toastify';
import Dropdown from './Dropdown';
import '../../styles/Components/profile-dropdown.scss';
import {
  profileIcon
} from '../Icons/icons';
// import User from '../../models/user';
import cuid from 'cuid';
import Modal from './Modal';
import RoundedTextArea from '../Input/RoundedTextArea';
import DefaultButton from '../Input/DefaultButton';
import useOutsideClick from './useOutsideClick.hook';
import useFormHooks from '../../utils/useFormHooks';
import ValidStates from '../Input/ValidStates';
import RoundedInputField from '../Input/RoundedInputField';

// const model = new User();
const notificationIcon = (
  <svg width="40px" height="47px" viewBox="0 0 40 47" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#000000" fillRule="nonzero">
            <path d="M20,47.0046083 C22.5852535,47.0046083 24.7004608,44.8894009 24.7004608,42.3041475 L15.2995392,42.3041475 C15.2995392,44.8894009 17.4147465,47.0046083 20,47.0046083 Z M35.2764977,32.9032258 L35.2764977,19.9769585 C35.2764977,12.6912442 30.3410138,6.8156682 23.5253456,5.17050691 L23.5253456,3.52534562 C23.5253456,1.64516129 21.8801843,0 20,0 C18.1198157,0 16.4746544,1.64516129 16.4746544,3.52534562 L16.4746544,5.17050691 C9.65898618,6.8156682 4.7235023,12.6912442 4.7235023,19.9769585 L4.7235023,32.9032258 L0.0230414747,37.6036866 L0.0230414747,39.9539171 L39.9769585,39.9539171 L39.9769585,37.6036866 L35.2764977,32.9032258 Z M30.5760369,35.2534562 L9.42396313,35.2534562 L9.42396313,19.9769585 C9.42396313,14.1013825 14.124424,9.40092166 20,9.40092166 C25.875576,9.40092166 30.5760369,14.1013825 30.5760369,19.9769585 L30.5760369,35.2534562 Z" id="Shape"></path>
        </g>
    </g>
</svg>
)
const ProfileDropdown = ({ model }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [image, setImage] = useState('');
  const [invites, setInvites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayFeedback, setDisplayFeedback] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const initialValues = {
    message: {
        value: '',
        message: '',
        regex: /.+/g,
        state: ValidStates.NotSet,
    },
    name: {
      value: '',
      message: '',
      regex: /.+/g,
      state: ValidStates.NotSet,
    }
  };
  const [updateValues, checkState, removeWhiteSpace, values] = useFormHooks(initialValues);
  const encode = (values) => {
    return Object.keys(values)
        .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(values[key])}`
        )
        .join('&');
}
  useOutsideClick(ref, () => {
    // Clicked outside
    setNotificationOpen(false);
    // setDisplayFeedback(false);
    setIsOpen(false);
  });
  
  const signOut = () => {
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      navigateTo('/sign-in/');
    }, function(error) {
      // An error happened.
    });
  }
  const presentModal = () => {
    // setDisplayFeedback(true);
    navigate('/contact')
  }
  useEffect(() => {
    firebase.auth().onAuthStateChanged((usr) => {
      if (usr) {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get()
          .then(async (doc) => {
            // If true then no expired else do the auto load;
            if (doc && doc.data() && doc.data().profile_url) {
              setImage(doc.data().profile_url)
            }
          });
      }
    });
    (async () => {
      try {
        const invites = await model.getAllInvites();

        if (invites.invalid) {
          toast.error("Sorry! There was an error retrieving invites. Please try again.", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          const items = await Promise.all(invites.map(async (invite) => {
            const invitee = await model.getCompanyById(invite.companyId)
            return {
              company: invitee,
              id: invite.id,
            }
          }))
          setInvites(items);
        }
        
      } catch (err) {
        toast.error("Sorry! There was an error retrieving invites. Please try again.", {
          position: toast.POSITION.TOP_LEFT
        });
        setInvites([]);
      }
    })();
  }, [])
  const handleProfileDropdown = () => {
    setIsOpen(!isOpen);
    setNotificationOpen(false);
  };
  const handleNotificationDropdown = () => {
    setNotificationOpen(!notificationOpen);
    setIsOpen(false);
  }
  const handleSubmit = async e => {
    const event = e || window.event;
    ////
    // The following is why microsoft sucks...screw you microsoft
    ////
    if (typeof window === 'undefined') {
      return;
    }
    if (typeof event.preventDefault === 'function') {
    event.preventDefault();
    }
    if (typeof event.stopPropagation === 'function') {
    event.stopPropagation();
    }
    event.returnValue = false;
    setIsLoading(true);
    try {
        const request = await axios.post('/', encode({
            'form-name': 'feedback',
            message: values.message.value,
            name: values.name.value,
        }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        setSubmitted(true);
    } catch (error) {
        // Handle Error
        toast.error("Sorry! There was an error submitting the form. Please try again.", {
            position: toast.POSITION.TOP_LEFT
          });
        console.log(error);
    } finally {
        setIsLoading(false);
    }
    
    return false;
  }
  const isValid = values.message.state === ValidStates.Valid;
  return (
    <div className="profile-dropdown" ref={ref}>
      <div className="profile">
        <button className="notifications-button" onClick={() => handleNotificationDropdown()}>
          {notificationIcon}
          {invites.length > 0 && (
            <div className="badge">
              {invites.length}
            </div>
          )}
        </button>
        {/* <button className="feedback-button" onClick={() => presentModal()}>Give us Feedback</button> */}
        <div onClick={() => handleProfileDropdown()}>
          {image !== '' ? <img src={image} alt="profile" /> : profileIcon}
        </div>
        <div className="caret" />
      </div>
      <Dropdown isOpen={isOpen}>
        <Link to="/portal/account/">Account</Link>
        {/* <Link to="/portal/settings/">Settings</Link> */}
        <div role="button" tabIndex={0} className="dropdown-link" onClick={signOut}>Sign Out</div>
        {/* <button>Leave message</button> */}
      </Dropdown>
      <Dropdown isOpen={notificationOpen} className="notification-dropdown">
        {invites && invites.length > 0 && invites.map((invite) => (
          <Link to="/portal/account/#invites" key={cuid.slug()}>Invite from {invite.company.name}</Link>
        ))}
        {invites.length === 0 && (
          <p>There are no new notifications</p>
        )}
      </Dropdown>
      {displayFeedback ? createPortal((
        <Modal
          title={submitted ? "Thanks for your feedback!" : "Give Us Feedback"}
          bodyCopy={submitted ? "We really appreciate your feedback. It allows us to develop a better product for you. We will look into your feedback as soon as we can." : "Feedback helps us build a better product for you."}
          onClose={() => setDisplayFeedback(false)}
        >
         {!submitted && (
          <form name="feedback" data-netlify="true" data-netlify-honeypot="bot-field" id="feedback-form" className="form-container feedback" onSubmit={handleSubmit}>
              <input type="hidden" name="bot-field" />
              <p>NAME</p>
              <RoundedInputField
                id="name"
                type="text"
                value={values.name.value}
                placeholder="Name"
                checkState={checkState}
                callback={e => updateValues(e)}
              />
              <p>FEEDBACK</p>
              <RoundedTextArea
                  id="message"
                  type="text"
                  placeholder="Message"
                  value={values.message.value}
                  checkState={checkState}
                  callback={e => updateValues(e)}
              />
              <DefaultButton
                  text="Submit"
                  disabled={!isValid}
                  loading={isLoading}
                  callback={() => {}}
              />
          </form>
         )}
        </Modal>
      ), document.body) 
      : null}
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </div>
  )
}

export default ProfileDropdown;
