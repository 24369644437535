import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/Components/video.scss';

const Video = ({ videoSrcURL, videoTitle, onClick, showButton, autoplay, ...props }) => (
  <div className="video">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow={`accelerometer; ${autoplay && 'autoplay;'} encrypted-media; gyroscope; picture-in-picture`}
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      {...props}
    />
    {showButton && <button className="video-button" type="button" onClick={() => onClick()}>View More</button>}
  </div>
);

Video.propTypes = {
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showButton: PropTypes.bool,
  autoplay: PropTypes.bool,
};

Video.defaultProps = {
  onClick: () => {},
  showButton: false,
  autoplay: false,
};

export default Video;
