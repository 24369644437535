import React from 'react';
import { Router } from '@reach/router';
import PortalHome from '../components/PrivateRoutes/index';
import CommentAI from '../components/PrivateRoutes/CommentAI';
import PrivateRoute from '../components/PrivateRoute';
import Account from '../components/PrivateRoutes/Account';
import Settings from '../components/PrivateRoutes/Settings';
import UserModel from '../models/user';

const PortalModel = new UserModel();
const Portal = () => (
  <Router basepath="/portal">
    <PrivateRoute path="/" component={PortalHome} activeTab="home" model={PortalModel} />
    <PrivateRoute path="/media-insights" component={CommentAI} activeTab="media-insights" model={PortalModel}/>
    <PrivateRoute path="/account" component={Account} activeTab="account" model={PortalModel} />
    <PrivateRoute path="/settings" component={Settings} activeTab="settings" model={PortalModel} />
  </Router>
);
export default Portal;
