class DashboardModel {
  constructor() {}

  createDataSet = (backgroundColor, label, borderColor, data, pointBackgroundColor, pointHoverBackgroundColor) => {
    const largeDataset = data.length > 20;
    return {
      label: label,
      fill: true,
      spanGaps: true,
      lineTension: 0.1,
      steppedLine: false,
      showLines: false,
      clip: 0,
      cubicInterpolationMode: 'default',
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      borderWidth: 2,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      pointBorderColor: 'rgba(255, 255, 255, 0)',
      pointBackgroundColor,
      pointBorderWidth: largeDataset ? 1 : 2,
      pointHoverRadius: largeDataset ? 1 : 3,
      pointHoverBackgroundColor,
      pointHoverBorderColor: 'rgba(255, 255, 255, 0)',
      pointHoverBorderWidth: largeDataset ? 1 : 2,
      pointRadius: largeDataset ? 1 : 3,
      pointHitRadius: 10,
      data,
    }
  }
}

export default DashboardModel;
