import React from 'react';

const profileIcon = (
  <svg width="512px" height="512px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="account" fillRule="nonzero">
            <path d="M256,511.996094 C114.515625,511.996094 0,397.339844 0,256 C0,114.511719 114.496094,0 256,0 C397.488281,0 511.996094,114.496094 511.996094,256 C511.996094,397.476562 397.328125,511.996094 256,511.996094 Z" id="Path" fill="#788B9A"></path>
            <path d="M256,0 L256,511.996094 C397.328125,511.996094 511.996094,397.476562 511.996094,256 C511.996094,114.5 397.488281,0 256,0 Z" id="Path" fill="#5C6A7E"></path>
            <path d="M256,316 C181.511719,316 110.488281,348.5625 58.582031,418.96875 C161.945312,543.910156 353.269531,542.84375 455.238281,416.738281 C430.058594,391.691406 373.34375,316 256,316 Z" id="Path" fill="#D6F3FE"></path>
            <path d="M455.238281,416.738281 C407.097656,476.265625 334.867188,511.996094 256,511.996094 L256,316 C373.347656,316 430.058594,391.699219 455.238281,416.738281 L455.238281,416.738281 Z" id="Path" fill="#85A3AD"></path>
            <path d="M256,271 C206.371094,271 166,230.625 166,181 L166,151 C166,101.375 206.371094,61 256,61 C305.625,61 346,101.375 346,151 L346,181 C346,230.625 305.625,271 256,271 Z" id="Path" fill="#D6F3FE"></path>
            <path d="M256,61 L256,271 C305.628906,271 346,230.628906 346,181 L346,151 C346,101.371094 305.628906,61 256,61 Z" id="Path" fill="#85A3AD"></path>
        </g>
    </g>
</svg>
);


export {
  profileIcon,
};
