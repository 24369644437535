import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Logo from '../../images/imgcomps/Logo';

const MobileSideBar = ({ activeTab, opened }) => (
  <div className={`side-bar mobile ${opened ? 'opened' : ''}`}>
    <div className="sidebar-wrapper">
      <div className="portal-logo">
        <Logo />
      </div>
      <ul className="sidebar-nav">
        <li className={activeTab === 'home' ? 'active' : ''}>
          <Link to="/portal">Dashboard</Link>
        </li>
        <li className={activeTab === 'media-insights' ? 'active' : ''}>
          <Link to="/portal/media-insights/">Media Insights</Link>
        </li>
        <li className={activeTab === 'account' ? 'active' : ''}>
          <Link to="/portal/account/">Account</Link>
        </li>
        {/* <li className={activeTab === 'settings' ? 'active' : ''}>
          <Link to="/portal/settings/">Settings</Link>
        </li> */}
      </ul>
    </div>
  </div>
);

MobileSideBar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default MobileSideBar;
