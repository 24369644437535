import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DefaultButton from '../Input/DefaultButton';

const EditModal = ({
  save,
  onClose,
  displayEditModal,
  comment,
}) => {
  const [editComment, setEditComment] = useState(comment);
  useEffect(() => {
    setEditComment(comment);
  }, [displayEditModal]);
  return (
    <>
      { displayEditModal && (
      <div className="modal-background">
        <div className="edit-modal">
          <div
            role="button"
            tabIndex={-1}
            className="close-button black"
            onKeyDown={() => {}}
            onClick={() => onClose()}
          />
          <h3>Edit Your Comment</h3>
          <p>By editing your comment you will train our AI to better respond for you.</p>
          <input
            className="input-field"
            id="editComment"
            value={editComment}
            onChange={e => setEditComment(e.target.value)}
          />
          <DefaultButton
            text="Save"
            callback={() => save(editComment)}
          />
        </div>
      </div>
      )}
    </>
  );
};

EditModal.propTypes = {
  save: PropTypes.func.isRequired,
  displayEditModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
};

export default EditModal;
